@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
    font-family: 'helvetica';
    src: url('../fonts/helvetica-webfont.woff2') format('woff2'),
         url('../fonts/helvetica-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.blue {color:#004AAD;}

.vip-yellow {color: #dba504;}
.pending {color: #dba504;}
.error {color:#c90505;}
.disabled {color: #505050;}
.profit-green {color:#00981e;}
.text-gray {color:#dcdcdc;}

.bg-dark-green {background-color:#00981e;}
.bg-blue {background-color: #004AAD;}
.bg-yellow {background-color: #dba504;}
.bg-orange {background-color: #d57500;}
.bg-red {background-color:#e23434;}
.bg-gray {background-color: #505050;}

.success-icon {
    font-size: 48px;
    color: #00981e;
}
.error-icon {
    font-size: 48px;
    color: #c90505;
}
.error-box {
    border-radius: 10px;
    background-color: #fdc6c6;
    padding: 5px 20px;
    color: #c90505;
}

.maintenance-icon {
    font-size: 64px;
    color: #c45500;
}

.main-button {
    font-family: "Montserrat", sans-serif;
    background-color: #00d3b0;
    color: #FFF;
    padding: 10px 20px;
}

.main-button:hover {
    background-color: #74ead7;
    color: #FFF;
    transition: 0.5s;
}

.setting-button {
    background-color: #00d3b0;
    color: #FFF;
    padding: 5px 10px;
}

.setting-button:hover {
    background-color: #74ead7;
    color: #FFF;
    transition: 0.5s;
}

.secondary-button {
    background-color: #cecece;
}


body {
    margin:0;
    padding:0;
    background: #f0f0f0;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
body::-webkit-scrollbar {
    display: none;
}

h1, h2, h3, h4, h5, h6 {font-family: 'helvetica'; font-weight: 700;}

h3 {font-size: 24px;}
@media (max-width: 991px) {
    h3 {font-size: 20px;}
}

p {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
}
@media (max-width: 575px) {
    p {font-size: 14px;}
}

.fluid-padding {
    padding-left: 15px;
    padding-right: 15px;
}
@media (min-width: 1200px) {
    .fluid-padding {
        padding-left: 30px;
        padding-right: 30px;
    }
}
@media (min-width: 1600px) {
    .fluid-padding {
        padding-left: 50px;
        padding-right: 50px;
    }
}

.form-bg {
    background-color: #eeecec;
}

.form-page-padding {
    padding-left: 5%;
    padding-right: 5%;
}
@media (min-width: 767px) {
    .form-page-padding {
        padding-left: 15%;
        padding-right: 15%;
    }
}




.desktop-sm {
    display: block;
}
@media (max-width: 575px) {
    .desktop-sm {
        display: none;
    }
}
.mobile-sm {
    display: none;
}
@media (max-width: 575px) {
    .mobile-sm {
        display: block;
    }
}
.desktop-md {
    display: block;
}
@media (max-width: 767px) {
    .desktop-md {
        display: none;
    }
}
.mobile-md {
    display: none;
}
@media (max-width: 767px) {
    .mobile-md {
        display: block;
    }
}
.desktop-lg {
    display: block;
}
@media (max-width: 991px) {
    .desktop-lg {
        display: none;
    }
}
.mobile-lg {
    display: none;
}
@media (max-width: 991px) {
    .mobile-lg {
        display: block;
    }
}
