/* General Styling */
.action-button {
  border: 2px solid #004AAD;
  background-color: #004AAD;
  border-radius: 10px;
  padding: 5px 20px;
}
@media (max-width: 991px) {
  .action-button {
    margin-top: 5px;
  }
}

.action-button p {
  color: #DCDCDC;
  font-size: 14px;
}
@media (max-width: 575px) {
  .action-button p {
    font-size: 12px;
  }
}
.action-button:hover {
  transition: 0.5s;
  background: #004AAD;
  border: 2px solid #004AAD;
}

.unselected-btn {
  background-color: #1c202e;
  border: 2px solid #1c202e;
}

.remove-btn {
  background-color: #af0000;
  border: 2px solid #af0000;
  border-radius: 10px;
}
@media (max-width: 991px) {
  .remove-btn {
    padding: 8px 20px;
    margin-top: 5px;
  }
}
.remove-btn:hover {
  transition: 0.5s;
  background: #860101;
  border: 2px solid #860101;
}

.secondary-btn {
  background-color: #eeeeee;
  border: 1px solid #bdbdbd;
  border-radius: 10px;
  padding: 8px 30px;
}


.timestamp-text {
  color: #404040;
  font-size: 16px;
}




/* Login / Signup Page */
.full-page {
  min-height: 100vh;
  padding: 80px 0px;
  /* background-image: url(../img/desktop-bg.jpg);
  background-repeat: no-repeat;
  background-position: 60%;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover; */
  background-color: #959698;
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.full-page::-webkit-scrollbar {
  display: none;
}

.login-page {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.login-form {
  width: 600px;
}
@media (max-width: 767px) {
  .login-form {
    width: 400px;
  }
}
@media (max-width: 575px) {
  .login-form {
    width: 280px;
  }
}
.form-logo {
  width: 100%;
  max-width: 200px;
}

/* Form Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.review-text-field {
  background-color: #f8f8f8;
  border: 1px solid #c6c6c6;
  padding: 20px 10px;
  border-radius: 10px;
}






/* Header Styling */
.header {
  background-color: #2e2e2e;
  padding: 10px 0px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.collapse-btn-icon {
  color: #DCDCDC;
  font-size: 24px;
}








/* Side Bar Styling */
.ps-sidebar-container, .ps-submenu-content {
  background-color: #2e2e2e !important;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.ps-sidebar-container::-webkit-scrollbar, .ps-submenu-content::-webkit-scrollbar {
  display: none;
}
.ps-submenu-content {
  min-width: 280px !important;
}

.side-bar-container {
  height: 100vh;
  position: fixed;
  z-index: 120;
}

.side-menu-brand-area {
  width: 100%;
  padding: 20px 15px;
  display: flex;
}
.side-menu-logotext {
  width: 100%;
  max-width: 180px;
}
@media (max-width: 767px) {
  .side-menu-logotext {
    max-width: 180px;
  }
}
.side-menu-logo {
  width: 50px;
}

.collapse-icon {
  color: #DCDCDC;
  font-size: 22px;
}

.side-menu-icon {
  font-size: 22px;
}

.side-menu-text {
  margin-top: 20px;
  color: #DCDCDC;
  font-size: 14px;
}

.side-menu-coming-soon, .side-menu-off, .side-menu-on, .side-menu-new, .side-menu-maintenance {
  border-radius: 5px;
  padding: 1px 5px;
  font-size: 13px;
}
@media (max-width: 575px) {
  .side-menu-coming-soon, .side-menu-off, .side-menu-on, .side-menu-new, .side-menu-maintenance {
    font-size: 12px;
  }
}
.side-menu-coming-soon {
  color: #000;
  background-color: #c45500;
}
.side-menu-off {
  background-color: #830000;
}
.side-menu-on {
  background-color: #008311;
}
.side-menu-new {
  color: #000;
  background-color: #1CFFD2;
}
.side-menu-maintenance {
  color: #000;
  background-color: #e8c100;
}




/* Main Content Area */
.main-content {
  width: 100%;
  height: calc(100vh - 55px);
  margin-top: 55px;
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.main-content::-webkit-scrollbar {
  display: none;
}


.page-banner {
  width: 100%;
}

.currency-lg {
  font-weight: 700;
  font-size: 28px;
}
@media (max-width: 991px) {
  .currency-lg {
    font-size: 24px;
  }
}

.page-container, .page-sub-container {
  border-radius: 10px;
  border: 1px solid #bdbdbd;
}
.page-container {
  padding: 20px;
  background-color: #e6e6e6;
}
.page-sub-container {
  background-color: #dcdcdc;
  padding: 15px 20px;
}
@media (max-width: 767px) {
  .page-sub-container {
    padding: 15px;
  }
}


/* Listing Reviews */
.listing-title {
  font-size: 22px;
}
@media (max-width: 767px) {
  .listing-title {
    font-size: 18px;
  }
}
@media (max-width: 575px) {
  .listing-title {
    font-size: 16px;
  }
}

.review-stars {
  color: #dba504;
  font-size: 16px;
}

.location-icon {
  font-size: 18px;
}

.booking-price {
  font-weight: 700;
  font-size: 28px;
}
@media (max-width: 767px) {
  .booking-price {
    font-size: 20px;
  }
}
@media (max-width: 575px) {
  .booking-price {
    font-size: 24px;
  }
}






/* Dashboard */
.data-box {
  background-color: #e6e6e6;
  border: 1px solid #bdbdbd;
  border-radius: 10px;
  padding: 10px;
}

.data-icon-box {
  padding: 10px;
  border-radius: 10px;
}

.data-text-box {
  padding: 10px;
}

.data-box-icon {
  font-size: 32px;
}

.data-box-heading {
  font-size: 18px;
}

.data-box-number {
  font-size: 18px;
  font-weight: bold;
}







.product-img {
  width: 120px;
}













